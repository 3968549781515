<template>
  <div class="apply-container">
    <van-nav-bar
      :title="$t('form.title')"
      :left-text="$t('back')"
      left-arrow
      @click-left="toOrder"
    >
    </van-nav-bar>
    <div class="apply-content">
      <div class="apply-gap"></div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.req_user')}}</label>
          <input :placeholder="$t('form.req_user')" v-model="order.req_user" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.conn_user')}}</label>
          <input :placeholder="$t('form.conn_user')" v-model="order.conn_user" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.conn_email')}}</label>
          <input :placeholder="$t('form.conn_email')" v-model="order.conn_email" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.conn_phone')}}(07xxxxxxxx)</label>
          <input :placeholder="$t('form.conn_phone')" v-model="order.conn_phone" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label>{{$t('form.company')}}</label>
          <input :placeholder="$t('form.company')" v-model="order.company_name" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.to_address')}}</label>
          <input :placeholder="$t('form.to_address')" v-model="order.to_address" id="toAddress" />
          <van-loading color="#1989fa" size="24px" v-if="inSearch" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label>{{$t('form.lgh')}}</label>
          <input :placeholder="$t('form.lgh')" v-model="lgh" />
        </div>
        <div class="apply-item van-hairline--bottom" @click="showAlert">
          <label required>{{$t('form.postal_code')}}(xxxxx)</label>
          <input :placeholder="$t('form.postal_code')" v-model="order.postal_code" readonly disabled />
        </div>
        <div class="apply-item van-hairline--bottom" @click="showAlert">
          <label required>{{$t('form.city')}}</label>
          <input :placeholder="$t('form.city')" v-model="order.city" readonly disabled />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label>{{$t('remark')}}</label>
          <input :placeholder="$t('remark')" v-model="order.remark" />
        </div>
      </div>
      <div class="apply-gap"></div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.deliveryType')}}</label>
          <van-radio-group v-model="order.delivery_type">
            <van-radio :name="30" style="margin-bottom:8px">单程 （补件，使馆费为0，适用于补材料的快递件）</van-radio>
            <van-radio :name="20" style="margin-bottom:8px">单程 （派送，适用于从使领馆发出的单程件）</van-radio>
            <van-radio :name="10">往返</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="apply-gap"></div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.selectDipm')}}</label>
          <van-cell is-link :title="order.dipm_name || $t('form.selectDipmTip')" @click="showDipm = true" :class="{ 'c-gray': !order.dipm_id}" />
        </div>
        <template v-if="order.delivery_type && order.delivery_type != 30">
          <div class="apply-item van-hairline--bottom dipm-item" v-for="(item, index) in order.orderCosts" :key="index">
            <div class="apply-item-hd">
              <label required>{{$t('form.type')}}</label>
              <van-checkbox checked-color="#ED6900" shape="square" :icon-size="16" v-model="item.is_urgent" v-if="item.cost_id > 0">{{$t('form.urgent')}}</van-checkbox>
              <span class="c-red mlm" @click="delItem(index)" v-if="item.cost_id > 0">{{$t('form.cancel')}}</span>
            </div>
            <van-cell is-link :title="item.cost_name || $t('form.selectTip')" @click="showTypes(index)" :class="{ 'c-gray': !item.cost_name }" />
          </div>
          <div class="apply-add" @click="addDipm">
            <svg-icon icon-class="plus"></svg-icon>
            <span>{{$t('form.addType')}}</span>
          </div>
        </template>
        <van-action-sheet
          v-model="show"
          :actions="typesShow"
          @select="doSelect"
          close-on-click-action
        />
        <van-action-sheet
          v-model="showDipm"
          :actions="dipmList"
          :cancel-text="$t('form.cancel')"
          close-on-click-action
          @select="selectDipm"
        />
      </div>
      <div class="apply-fee" v-if="order.dipm_id">{{$t('embassyFee')}}：{{ dipm_fee || 0 }} {{ pay_type }}<span class="express-fee">{{ $t('expressFee') }}：{{delivery_fee}} {{ pay_type }}</span></div>
      <div class="apply-gap"></div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label>{{$t('form.attach')}}</label>
          <uploader
            :afterRead="afterRead"
            v-model="order.files"
          />
        </div>
      </div>
    </div>
    <div class="apply-submit">
      <van-button block round type="warning" :loading="loading" :loading-text="$t('submiting')" @click="onSubmit">{{$t('submit')}}</van-button>
    </div>



    <!-- 地址选择提示 -->
    <van-dialog v-model="alertVisible" title="地址选择提示" closeOnClickOverlay>
      <div class="apply-alert">
        <div class="apply-alert-content">
          <span>邮编及城市为自动填充，请在地址栏输入地址，选择地址所在城市。请不要在地址栏内填写城市及国家！如果填写地址时，城市不能自动填充，请在地址后加个逗号，例：Hamngatan 29,</span>
        </div>
        <img src="/static/google_address_search.png" />
      </div>
    </van-dialog>
        <!-- 地址选择提示 -->
    <van-dialog v-model="alertVisibleTips" title="温馨提示" :before-close="onBeforeClose">
      <div class="apply-alert">
        <div class="apply-alert-content">
          <span>受疫情及当前局势影响，我司派送费自2022年9月16日起调整为单程<span style='color:red;'>130</span>克朗，往返<span style='color:red;'>260</span>克朗，申请单审核通过后我司<span style='color:red;'>无法退款</span>，请您谨慎下单！对此造成的不便我们深表歉意，敬请谅解！</span>
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script type="text/javascript">
import orderApi from '@/api/order'
import fileApi from '@/api/file'
import dipmApi from '@/api/dipm'
import { isEmail } from '@/utils/validate'
import { mapState } from 'vuex'
import { Uploader } from 'vant'
export default {
  components: {
    Uploader,
  },
  data() {
    return {
      order: {
        delivery_fee: 0,
        orderCosts: [{}],
        files: [],
        to_address: '',
        postal_code: '',
        city: '',
        delivery_type: '',
      },
      alertVisible: false,
      alertVisibleTips: true,
      dipm: {},
      inSearch: false,
      dipmList: [
        { id: 1, name: '中华人民共和国驻瑞典大使馆' },
        { id: 7, name: '中华人民共和国驻哥德堡总领事馆' }
      ],
      lgh: '',
      types: [],
      show: false,
      loading: false,
      showDipm: false,
      pay_type_name: '',
      pay_type: '',
    }
  },
  computed: {
    ...mapState(['user', 'locale']),
    dipm_fee() {
      let total = 0
      this.order.orderCosts.map(v => {
        if (v.cost_id) {
          const type = this.types.find(item => item.id === v.cost_id)
          if (type) {
            total += Number(type.price) + (v.is_urgent ? Number(type.urgent_fee || '') : 0)
          }
        }
      })
      return total
    },
    delivery_fee() {
      return this.getDeliveryFee()
    },
    typesShow() {
      return this.types.map(v => ({ ...v, name: this.locale === 'zh-CN' ? v.name : v.name_en }))
    }
  },
  created() {
    const { uuid } = this.$route.query
    if (uuid) {
      this.getDetail(uuid)
    }
    else {
      this.order = {
        ...this.order,
        conn_email: this.user.email || '',
        conn_user: this.user.nickname || '',
        conn_phone: this.user.phone || ''
      }
    }
    this.getDipmList()
  },
  mounted() {
    const toAddress = document.querySelector('#toAddress')
    this.autocomplete = new google.maps.places.Autocomplete(toAddress, {
      componentRestrictions: { country: ["se", 'chn'] },
      fields: ["address_components", "geometry", 'place_id'],
      types: ["address"],
    })
    this.autocomplete.addListener("place_changed", this.fillInAddress)
  },
  methods: {
    fillInAddress() {
      const place = this.autocomplete.getPlace()
      let streatNumber = ''
      let route = ''
      let postcode = ''
      let city = ''
      let sublocality_level_1 = ''
      console.log(place)

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            streatNumber = component.long_name
            break;
          }

          case "route": {
            route = component.short_name
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          // 类似区一级
          case 'sublocality_level_1':
            //sublocality_level_1 = component.short_name
            break;
          case 'locality':
          case 'postal_town':
            city = component.short_name
            break
          case "locality":
            //document.querySelector("#locality").value = component.long_name;
            break;

          case "administrative_area_level_1": {
            //document.querySelector("#state").value = component.short_name;
            break;
          }
          case "country":
            //document.querySelector("#country").value = component.long_name;
            break;
        }
      }

      this.order = {
        ...this.order,
        to_address: `${sublocality_level_1 ? sublocality_level_1 + ' ' : ''}${route} ${streatNumber}`,
        postal_code: postcode.replace(/[^0-9]/ig, ''),
        g_place_id: place.place_id || '',
        city
      }
      //address1Field.value = address1;
      //postalField.value = postcode;
      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      //address2Field.focus();
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('form.uploading') + '...'
      let fd = new FormData()
      fd.append('file', file.file)
      fileApi.upload(fd)
      .then((id) => {
        file.id = id
        file.status = 'done'
        file.message = ''
      })
      .catch(() => {
        file.status = 'failed'
        file.message = this.$t('form.uploadFailed')
      })
    },
    getDetail(uuid) {
      orderApi.detail(uuid)
      .then(res => {
        if (res) {
          let addressInfo = res.to_address.split(/,?\s*lgh\s*/i)
          this.order = {
            ...res,
            to_address: addressInfo[0],
          }
          this.lgh = (addressInfo[1] || '').trim()
          // 初始化使馆信息
          this.getTypes()
        }
      })
    },
    getDipmList() {
      orderApi.getDipmList()
      .then(res => {
        if (res) {
          console.log(res)
          this.dipmList = res
        }
      })
    },
    getDeliveryFee() {
      const { delivery_type } = this.dipm
      // TODO 暂时这样写死
      const dft_fee = this.order.delivery_type === 10 ? 260 : 130
      return dft_fee
      // eslint-disable-next-line
      if (!delivery_type || !delivery_type.length) return dft_fee
      let fee = 0
      for (let item of delivery_type) {
        if (item.fee > fee) {
          fee = item.fee
        }
      }
      return fee || dft_fee
    },
    getTypes() {
      const cb = this.$toast.loading({
        message: this.$t('loading'),
        duration: 0,
        forbidClick: true
      })
      dipmApi.info(this.order.dipm_id)
      .then(res => {
        this.pay_type = res.pay_type
        this.pay_type_name = res.pay_type_name
        this.dipm = res

        this.types = (res.orderCosts || []).map(v => ({
          id: v.id,
          price: v.price,
          urgent_fee: v.urgent_fee,
          name: v.type_name,
          name_en: v.type_name_en,
          subname: `${this.$t('embassyFee')} ${v.price} ${v.pay_type} (${this.$t('urgentFee')} ${v.urgent_fee || 0} ${v.pay_type})`,
        }))
        cb.clear()
      })
      .catch(cb.clear)
    },
    delItem(index) {
      if (this.order.orderCosts.length <= 1) {
        this.$toast(this.$t('form.typeTip'))
        return false
      }
      this.order.orderCosts = this.order.orderCosts.filter((v, i) => i !== index)
    },
    showTypes(index) {
      if (!this.order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return;
      }
      this.show = true
      this.showIndex = index
    },
    selectDipm(item) {
      this.order.dipm_id = item.id
      this.order.dipm_name = item.name
      this.order.orderCosts = [{}]
      this.getTypes()
    },
    doSelect(item) {
      const { orderCosts } = this.order
      this.order = {
        ...this.order,
        orderCosts: orderCosts.map((v, i) => {
          if (i === this.showIndex) {
            v = {
              cost_id: item.id,
              is_urgent: 0,
              cost_name: item.name,
            }
          }
          return v
        })
      }
    },
    toOrder() {
      this.$router.push(`/`)
    },
    addDipm() {
      if (!this.order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return;
      }
      this.order.orderCosts = [ ...this.order.orderCosts, {} ]
    },
    async onSubmit() {
      await this.$dialog.confirm({
        title: '提示',
        message: '揽派件有时会由第三方完成。',
        confirmButtonText: '我同意',
        cancelButtonText: '我拒绝',
        cancelButtonColor: '#ee0a24',
        confirmButtonColor: '#1880ff',
      })

      const { order: { files, ...order } } = this
      if (!order.delivery_type || !order.conn_email || !order.conn_user || !order.conn_phone || !order.req_user || !order.city) {
        this.$toast(this.$t('form.errTip'))
        return false
      }
      // 判断申请人名字
      if (!/^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/.test(order.req_user)) {
        this.$toast(this.$t('form.connUserTip'))
        return false
      }
      if (!/\w/.test(order.conn_user)) {
        this.$toast('收/发件人姓名必须为中文+拼音')
        return false
      }
      // 判断手机号格式
      if (!/^07[0-9]{8}$/.test(order.conn_phone)) {
        this.$toast(this.$t('form.connPhoneTip'))
        return false
      }
      // 判断邮编
      if (!/^\d{5}$/.test(order.postal_code)) {
        this.$toast(this.$t('form.postalCodeTip'))
        return false
      }
      // 判断邮箱格式
      if (!isEmail(order.conn_email)) {
        this.$toast(this.$t('form.connEmailTip'))
        return false
      }
      // 判断lgh
      if (this.lgh && !/^\d+$/.test(this.lgh.trim())) {
        this.$toast(this.$t('form.lghTip'))
        return false
      }
      if (!order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return false
      }
      if (!order.delivery_type || !this.delivery_fee) {
        this.$toast(this.$t('form.deliveryTypeTip'))
        return false
      }
      const orderCosts = order.orderCosts.filter(v => v.cost_id > 0).map(v => ({ cost_id: v.cost_id, is_urgent: v.is_urgent ? 1 : 0 }))
      if (!orderCosts.length && order.delivery_type != 30) {
        this.$toast(this.$t('form.typeTip'))
        return false
      }

      if (order.delivery_type == 30) {
        await this.$dialog.confirm({
          title: '提示',
          message: '此选项为单程补件，仅适用于申请材料提交不足，使领馆要求补件的情况下的单程揽件，费用也为单程80瑞典克朗，如您需要从您家中揽件并且需要使领馆处理完成后再派送至您家中的服务，请选择：往返。',
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          cancelButtonColor: '#ee0a24',
          confirmButtonColor: '#1880ff',
        })
      }

      if (order.delivery_type == 20) {
        await this.$dialog.confirm({
          title: '提示',
          message: '请确认您选择的是：单程派送服务！此服务仅适用于从使领馆发出的文件，您不会收到揽件通知及面单，如需揽件服务，请选择往返或单程补件。',
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          cancelButtonColor: '#ee0a24',
          confirmButtonColor: '#1880ff',
        })
      }

      // 提交
      this.loading = true
      let body = {
        ...order,
        orderCosts,
        delivery_fee: this.delivery_fee,
        file_ids: files.filter(v => v.status === 'done' || v.id).map(v => v.id),
        to_address: order.to_address + (this.lgh ? `, LGH ${this.lgh}` : '')
      };
      if (order.delivery_type == 30) {
        body.dipm_fee = 0
        delete body.orderCosts
      }
      /* eslint-disable-next-line */
      delete body.dipm_fee_status
      delete body.delivery_fee_status
      if (order.review_status == 3) {
        body.review_status = 1
      }
      (order.uuid > 0 ? orderApi.update(order.uuid, body) : orderApi.add(body))
      .then(() => {
      })
      .then(() => {
        this.loading = false
        this.$toast.success(this.$t('form.successTip'))
        this.$router.push(`/`)
      })
      .catch(() => {
        this.loading = false
      })
    },
    showAlert() {
      
    },
    onBeforeClose(){
      this.alertVisibleTips = false
      this.alertVisible = true
    }

  }
}
</script>
